import { render, staticRenderFns } from "./index.vue?vue&type=template&id=faa7d436"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextHtml: require('/var/www/html/glamoraV2/frontend-2/components/text/html/index.vue').default,CardLauncher: require('/var/www/html/glamoraV2/frontend-2/components/card/launcher/index.vue').default,BtnPrimary: require('/var/www/html/glamoraV2/frontend-2/components/btn/primary/index.vue').default,Block: require('/var/www/html/glamoraV2/frontend-2/components/block/index.vue').default})
